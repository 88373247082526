import { Injectable } from '@angular/core';

import { AuthProvider } from 'src/app/providers/auth';
import { Observable } from 'rxjs';

import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Globals } from 'src/app/providers/globals';
import { StorageService } from 'src/app/providers/storage.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(
  	public auth: AuthProvider, 
  	public router: Router,
    public storage: StorageService,
    public globals: Globals
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {

    return new Observable<boolean>((observer) => {
        this.storage.get('loginData').then((loginData) => {                  
          if(loginData!=null){
              this.router.navigate(['']);

              observer.next(false);  
              observer.complete();    
          }else{
            observer.next(true);
            observer.complete();
          }
        });                       
    });
  }  

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {

    return this.canActivate(route, state);
  }
}
