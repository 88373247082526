import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Globals } from 'src/app/providers/globals';

import {HttpHandler, 
        HttpEvent, 
        HttpInterceptor, 
        HttpRequest, 
        HttpErrorResponse} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { AuthProvider } from 'src/app/providers/auth';
import { StorageService } from 'src/app/providers/storage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        public globals: Globals, 
        public auth: AuthProvider, 
        public storage: StorageService,
        private router: Router
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {         
        if(this.globals.loginData){
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${this.globals.loginData.access_token}`
              }
            });            
        }
            
        return  next
                .handle(request)
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        let errorMessage = '';

                        if (error.error instanceof ErrorEvent) {
                            // client-side error
                            errorMessage = `Error: ${error.error.message}`;
                        } else {
                            // server-side error
                            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                        }

                        if (error.status === 401 || error.status === 403) {
                            this.globals.loginData = null;

                            this.storage.remove('loginData');
                            this.storage.remove('role');                                    

                            this.router.navigate([`login`]);

                            return throwError(errorMessage);
                        }                

                        return throwError(errorMessage);
                    })
                );
    }    
}