import { Subject } from 'rxjs';

export class BaseService {
	waitingForManagement: any = false;
	list: any[];
	baseUrl: any;
	url: any;
	token: any;
	serverError: any;
	
	//Paging management
	lastRequestReturnedValues: boolean = false;		
	hasItems: boolean = false;
	currentPageNumber: any = 0;
	totalPages: any = 0;
	page: any = 1;
	size: any = 10;
	term: any = "";
	
	lookups: any = {};

	mode: any = 'list';

	managementChange = new Subject();
    itemManagedEvent = new Subject();

    firebasedb: any;

	constructor() {
		this.list = [];
	}

	resetPaging(){
		this.page = 1;
		this.size = 10;
		this.term = "";
	}

	clearPaging(){
		this.list = [];
		this.page=1;
		this.size=10;
		this.term = "";
	}	

	clearError(){
		this.serverError = "";
	}

	setError(error){
		this.serverError = error;
	}	

	pushItem(item){
		this.list.push(item);
	}

	pushItems(items){
		var count = items.length;

		for (var i = 0; i < count; i++) {			
			this.list.push(items[i]);
		}		
	}

	updateItem(item){
		let filteredlist: any[] = this.list.filter(d => d.Id === item.Id);

		Object.assign(filteredlist[0], item);
	}

	updateItemByField(id, field, item){
		for (var i = 0; i <= this.list.length -1; i++) {
			if(this.list[i][field] === id){
				this.list[i] = item;
			}
		}
	}

	removeItem(id){
		for (var i = this.list.length - 1; i >= 0; i--) {
			if(this.list[i].Id == id){
				this.list.splice(i, 1);
				break;
			}
		}
	}	

	pageData(items){
		this.hasItems = (items.length == 0) ? false : true;

		if(this.hasItems){
			this.lastRequestReturnedValues = (items.length==0) ? false : true;

			if(items.length>0){				
				this.currentPageNumber = items[0].PageNumber;
				this.totalPages = items[0].TotalPages;

				if(this.page == 1){
					this.list = items;	
				}else{
					this.pushItems(items);	
				}					
			}			
		}else{
			this.list=[];
			this.totalPages = 0;
		}
	}

	clearFirebaseEvents(){
		if(this.firebasedb){
			this.firebasedb.off();
		}			
	}
};