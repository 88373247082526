import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';

import { Globals } from 'src/app/providers/globals';
import { StorageService } from 'src/app/providers/storage.service';
import { PushNotificationHandlerProvider } from 'src/app/providers/push-notification-handler';
import { StadiumSeatSelectService } from 'src/app/providers/stadium-seat-select.service';
import { SplashScreen } from '@capacitor/splash-screen';

import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { StatusBar, Style } from '@capacitor/status-bar';

import * as firebase from 'firebase';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  hasCordova: boolean = false;
  
  constructor(
    public platform: Platform, 
    public globals: Globals,
    public storage: StorageService,
    public stadiumSeatSelectService: StadiumSeatSelectService,        
    private pushNotificationHandlerProvider: PushNotificationHandlerProvider
    ) 
  {
    platform.ready().then((readySource) => {    
      if(readySource!="dom"){
        this.hasCordova = true;
      }  

      this.storage.get('loginData').then((loginData: any) => {        
        if(loginData){
          this.globals.loginData = JSON.parse(loginData);  
        }          
      });

      this.globals.readySourceGlobal = readySource;


      this.loadFirebaseConfig();

      console.log("this.hasCordova");
      console.log(this.hasCordova);

      if(this.hasCordova){        
        StatusBar.setStyle({ style: Style.Dark });

        setTimeout(()=>{
          SplashScreen.hide();
        }, 2000);        
      }
    }); 
  }

  cancelScan(){
    BarcodeScanner.stopScan();
    document.body.classList.remove("qrscanner"); 

    this.stadiumSeatSelectService.scanEvent.next();
  }

  loadFirebaseConfig(){
    //This Firebase configuration is used for the realtime messaging 
    //at FE level and is not related to push notifications
    // var firebaseConfig = {
    //   apiKey: "AIzaSyD_d5SmCzR-YYwQkXo2Zepqbq1Vl6R3UtI",
    //   authDomain: "cashimiro-744ea.firebaseapp.com",
    //   databaseURL: "https://cashimiro-744ea.firebaseio.com",
    //   projectId: "cashimiro-744ea",
    //   storageBucket: "cashimiro-744ea.appspot.com",
    //   messagingSenderId: "897873227010",
    //   appId: "1:897873227010:web:515301b233b193fbea661b",
    //   measurementId: "G-TLX6JTXNJN"
    // };

    const firebaseConfig = {
      apiKey: "AIzaSyBVHXEerwJ7VJogPsK-laeUYOxfgEjbVQE",
      authDomain: "cashimiro-68257.firebaseapp.com",
      databaseURL: "https://cashimiro-68257.firebaseio.com",
      projectId: "cashimiro-68257",
      storageBucket: "cashimiro-68257.appspot.com",
      messagingSenderId: "1007722013438",
      appId: "1:1007722013438:web:ca60009e185fac0b633baf",
      measurementId: "G-N0T6CK1J7K"
    };

    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);    

    console.log("before this.hasCordova")

    // this.pushNotificationHandlerProvider.testPrompt();

    if(this.hasCordova){
      console.log("this.hasCordova")

      this.pushNotificationHandlerProvider
      .notificationSetup()
      .then(()=>{
        this.pushNotificationHandlerProvider.subscribeToMain();
      });     
    }
  }  
}
