import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Globals } from '../providers/globals';

import { BaseProvider } from './base-provider';

@Injectable()
export class ConsumerHandlerProvider extends BaseProvider{
	url: any;
	currentIdentityUserId: any;

	constructor(
		public http: HttpClient,
		public globals: Globals,
	){
		super();
	}

	getProfile(cb){	
	  	this.url = this.globals.dataUrl + 'consumer/profile';

	    this.http.get(
	        this.url
	    )
	    .subscribe(
	    	(res:any) => {
	          if(typeof cb == "function") cb(res.Data);
	    	}
	    );   
  	}

	updateProfileImage(params){		
	  	this.url = this.globals.dataUrl + 'consumer/profile/image';

	    this.http.post(
	        this.url,
	        {ImagePath: params.imageString}
	    )
	    .subscribe(
			(res:any) => {				
				if(typeof params.cb == "function") params.cb(res.Data);
			}, 
			(err) => {
				console.log("Returned error:");
				console.log(err);
			}
	    ); 
  	}

  	getUserInfo(params){
  		if(typeof(params.userId)=="string"){
  			this.url = this.globals.dataUrl + 'consumer/profile/' + params.userId;
  		}else{
  			this.url = this.globals.dataUrl + 'consumer/profile/user/' + params.userId;	
  		}
	  	
	    this.http.get(
	        this.url
	    )
	    .subscribe(
	    	(res:any) => {
	          if(typeof params.cb == "function") params.cb(res.Data);
	    	}
	    );   
  	}
}
