import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Globals } from '../providers/globals';
import { Utils } from '../helpers/utils';

import { BaseProvider } from './base-provider';
import { Subject } from 'rxjs';	

@Injectable()
export class ConsumerBusinessHandlerProvider extends BaseProvider {
	url: any;
	token: any;
	business: any;
	mainBusiness: any = {Id:0};

	debitList: any = [];
	creditsList: any = [];
	businessList: any = [];

	businessIsCurrentlyActive: boolean = false;

	wentToOrderEvent = new Subject();

	showedSplash: boolean = false;
	
	constructor(  		
		public utils: Utils,    
		public http: HttpClient,
		public globals: Globals
		) {    
		super();
	}

	refreshBusiness(){
		//Reload businesses
		this.getBusinessMoney({page:1,size:20});
		//Reload business detail
		this.getBusinessMoneyById({id:this.business.Id});
		//Reload credits
		this.getUserPurchases({
			businessId:this.business.Id, 
			page:1,
			size:20
		});
	}

	lastParams: any; 

	getBusinesses(params){	
		this.lastParams = params;
		
		this.url = this.globals.dataUrl + 'consumerBusiness/whiskey/' + this.globals.whiskeyId +
										  '/latNE/' + this.lastParams.latNE +
										  '/latSW/' + this.lastParams.latSW +
										  '/longNE/' + this.lastParams.lngNE +
										  '/longSW/' + this.lastParams.lngSW +
										  '/page/' + this.lastParams.page +
										  '/size/' + this.lastParams.size + 
										  '?term=' + this.lastParams.term +
										  '&categories=' + this.lastParams.categories+
										  '&ignoreCoordinates=' + this.lastParams.ignoreCoordinates;
										  
	  this.http.get(
	    this.url
	  )
	  .subscribe((res:any) => {
	  		this.pageData(res.Data);

	  		this.businessList = this.list;

	      	if(this.lastParams.cb) this.lastParams.cb(res.Data);
	  });   
	}  

	getBusinessesV2(params){	
		this.lastParams = params;
		
		this.url = this.globals.dataUrl + 'consumerBusiness/whiskey/' + this.globals.whiskeyId +
										  '/latNE/' + this.lastParams.latNE +
										  '/latSW/' + this.lastParams.latSW +
										  '/longNE/' + this.lastParams.lngNE +
										  '/longSW/' + this.lastParams.lngSW +
										  '/page/' + this.lastParams.page +
										  '/size/' + this.lastParams.size + '/v2' +
										  '?term=' + this.lastParams.term +
										  '&categories=' + this.lastParams.categories+
										  '&ignoreCoordinates=' + this.lastParams.ignoreCoordinates;
										  	  
	  this.http.get(
	    this.url
	  )
	  .subscribe((res:any) => {
	  		this.pageData(res.Data);

	  		this.businessList = this.list;

	      	if(this.lastParams.cb) this.lastParams.cb(res.Data);
	  });  
	}  	

	checkBusinessAvailability(id){
		let isOpen = false;

		this.businessList.forEach((business)=>{
			if(id == business.Id && business.IsOpenForBusiness){
				isOpen = true;
			}
		})

		return isOpen;
	}	

	getBusinessMoney(params){	
		this.url = this.globals.dataUrl + 'consumerBusiness/whiskey/' + this.globals.whiskeyId +
										  '/money/page/' + params.page +
										  
										  '/size/' + params.size +
										  '?term=' + params.term +
										  '&business=' + params.id;
										  
		this.http.get(
			this.url
		)
		.subscribe((res:any) => {
			if(params.page==1){
				this.list = res.Data;
			}else{
				this.pushItems(res.Data);
			}
		
			if(params.cb) params.cb(res.Data);
		});   
	}  	

	getBusinessMoneyDetail(id){	
		return new Promise( (resolve, reject) => {			
			this.url = this.globals.dataUrl + 'consumerBusiness?id=' + id;
											  
			this.http.get(
				this.url
			)
			.subscribe((res:any) => {		
				this.business = res.Data;	
				
				resolve(res.Data);
			});   
		});
	}  		

	getBusinessMoneyDetailV2(id){	
		return new Promise( (resolve, reject) => {			
			this.url = this.globals.dataUrl + 'consumerBusiness/v2?id=' + id;
											  
			this.http.get(
				this.url
			)
			.subscribe((res:any) => {		
				this.business = res.Data;	
					

				resolve(res.Data);
			});   
		});
	}  	

	getBusinessSchedule(businessId){	
		return new Promise( (resolve, reject) => {
		  	this.url = this.globals.dataUrl + 'guestConsumerBusiness/business/' + businessId + "/schedule";

		    this.http.get(
		        this.url
		    )
		    .subscribe(
				(res:any) => {
					resolve(res.Data);
				},
				err => {reject(err)}
		   	);  
		});		
	}  

	// getBusinessAvailability(businessId){	
	// 	return new Promise( (resolve, reject) => {
	// 	  	this.url = this.globals.dataUrl + 'consumerBusiness/business/' + businessId + "/availability";

	// 	    this.http.get(
	// 	        this.url
	// 	    )
	// 	    .subscribe(
	// 			(res:any) => {
	// 				var isAvailable = res.Data;

	// 				// if(res.Data){
	// 				// 	this.getBusinessMoneyDetailV2(businessId)
	// 				// 	.then((res: any)=>{
	// 						resolve(isAvailable);
	// 				// 	});
	// 				// }else{
	// 				// 	resolve(res.Data);
	// 				// }									
	// 			},
	// 			err => {reject(err)}
	// 	   	);  
	// 	});		
	// }  	

	getBusinessAvailability(businessId){	
		return new Promise( (resolve, reject) => {
		  	this.url = this.globals.dataUrl + 'consumerBusiness/business/' + businessId + "/availability";

		    this.http.get(
		        this.url
		    )
		    .subscribe(
				(res:any) => {
					if(res.Data)
						this.getBusinessMoneyDetailV2(businessId);
					resolve(res.Data);
				},
				err => {reject(err)}
		   	);  
		});		
	}  	

	getBusinessMoneyById(params){	
		this.url = this.globals.dataUrl + 'consumerBusiness/whiskey/' + this.globals.whiskeyId +
										  '/money/page/1/size/1' +
										  '?business=' + params.id;
										  
		this.http.get(
			this.url
		)
		.subscribe((res:any) => {
			try{
				this.business = res.Data[0];
			}catch(e){}
			
			if(params.cb) params.cb(res.Data);
		});  
	}  		

	getBusinessMoneyByIdFromMain(params){	
		return new Promise( (resolve, reject) => {

			this.url = this.globals.dataUrl + 'consumerBusiness/whiskey/' + this.globals.whiskeyId +
											  '/money/page/1/size/1' +
											  '?business=' + params.id;
											  
			this.http.get(
				this.url
			)
			.subscribe((res:any) => {
				try{
					this.mainBusiness = res.Data[0];
				}catch(e){}
				
				resolve(this.mainBusiness);
			});  
		});       
	}  			

	getUserDebits(params){	
		this.url = this.globals.dataUrl + 'consumerMoney/debit' +
										  '/business/' + params.businessId +
										  '/page/' + params.page +
										  '/size/' + params.size;

		this.http.get(
			this.url
		)
		.subscribe((res:any) => {
			this.debitList = res.Data;
			if(params.cb) params.cb(res.Data);
		});  
	}  	

	getUserPurchases(params){	
		this.url = this.globals.dataUrl + 'consumerMoney/purchase' +
										  '/business/' + params.businessId +
										  '/page/' + params.page +
										  '/size/' + params.size;
										  										  
		this.http.get(
			this.url
		)
		.subscribe((res:any) => {
			this.creditsList = res.Data;
			if(params.cb) params.cb(res.Data);
		});   
	}  		

	getBusinessById(params){	
		this.url = this.globals.dataUrl + 'consumerBusiness/whiskey/' + this.globals.whiskeyId + '/' + params.id;
										    
	  this.http.get(
	    this.url
	  )
	  .subscribe((res:any) => {
	      if(params.cb) params.cb(res.Data);
	  });   
	}  	

	getBusinessByIdAndUser(params){	
		this.url = this.globals.dataUrl + 'consumerBusiness/whiskey/' + this.globals.whiskeyId + '/' + params.id + "/user";
										  
		this.http.get(
			this.url
		)
		.subscribe((res:any) => {
			if(params.cb) params.cb(res.Data);
		});   
	}  	
}

