import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Globals } from '../providers/globals';
import { StorageService } from 'src/app/providers/storage.service';

import { ConsumerBusinessHandlerProvider } from '../providers/consumer-business-handler';

@Injectable()
export class BusinessProductPaymentManagerHandler {
 	url: any;
 	token: any;
  	businessId: any = 0; 
  	invoice: any;  
  	
  	totalAmount: any = 0;
  	modifierAmount: any = 0;

  	product: any = {};

  	productPrice: any = 0;
  	productQty: any = 0;

	constructor(
		public http: HttpClient,
		public globals: Globals,
        public storage: StorageService,
        public consumerBusinessHandlerProvider: ConsumerBusinessHandlerProvider,
	){
	}

	getUser(params, cb){	
	  	this.url = this.globals.dataUrl + 'businessProductPayment/business/' + params.businessId + '/user/' + params.userId + '/device/' + params.deviceId;

	    this.http.get(
	        this.url
	    )
	    .subscribe((res:any) => {
			if(typeof cb == "function") cb(res.Data);
	    });  
  	}

  	getBusinessUser(cb){	
	  	this.url = this.globals.dataUrl + 'businessProductPayment/businessUser';
	      	
	    this.http.get(
	        this.url
	    )
	    .subscribe((res:any) => {
			if(typeof cb == "function") cb(res.Data);
	    });   
  	}

  	getCategories(params){	
  		let url = this.globals.dataUrl + 'businessProductPayment/categories';

  		let id = 	(this.consumerBusinessHandlerProvider.business) 
	  				? 
	  				"/" + this.consumerBusinessHandlerProvider.business.Id
	  				:
	  				"";

	  	this.url = url + id;

	    this.http.get(
	        this.url
	    )
	    .subscribe((res:any) => {
			if(typeof params.cb == "function") params.cb(res.Data);
	    });  
  	}

  	orderTypeId: any = 0;

  	getCategoriesByMainBusinessForOrderType(params){	
	  	this.url = this.globals.dataUrl + 'consumerOrderType/categories/' + this.globals.mainBusinessId + 
	  									  '/orderType/' + this.orderTypeId;

	    this.http.get(
	        this.url
	    )
	    .subscribe((res:any) => {
			if(typeof params.cb == "function") params.cb(res.Data);
	    }); 
  	}  	

  	getCategoriesByMainBusiness(params){	
	  	this.url = this.globals.dataUrl + 'businessProductPayment/categories/' + this.globals.mainBusinessId;

	    this.http.get(
	        this.url
	    )
	    .subscribe((res:any) => {
			if(typeof params.cb == "function") params.cb(res.Data);
	    });   
  	}  	  	

  	getProducts(params){	
  		let url = this.globals.dataUrl + 'businessProductPayment/subCategories';

  		let id = 	(this.consumerBusinessHandlerProvider.business) 
	  				? 
	  				"/" + this.consumerBusinessHandlerProvider.business.Id
	  				:
	  				"";

	  	this.url = url + id;

	    this.http.get(
	        this.url
	    )
	    .subscribe((res:any) => {
			if(typeof params.cb == "function") params.cb(res.Data);
	    });  
  	}

  	filterProducts(params){  
	    this.url = this.globals.dataUrl + 'businessProductPayment/search/subCategories'+                                   
	                                      '?term=' + params.term;

		this.http.get(
			this.url
		)
		.subscribe((res:any) => {
			if(params.cb) params.cb(res.Data);
		}); 
  	}  

  	getProductsBySubCategory(params){	
  		let id = 	(this.consumerBusinessHandlerProvider.business) 
	  				? 
	  				"/business/" + this.consumerBusinessHandlerProvider.business.Id
	  				:
	  				"";

	  	this.url = this.globals.dataUrl + 'businessProductPayment' +
	  									  '/subcategory/' + params.subcategoryId + 
	  									  id +
	                                      '/page/' + params.page +
	                                      '/size/' + params.size;

	    this.http.get(
	        this.url
	    )
	    .subscribe((res:any) => {
	    	if(typeof params.cb == "function") params.cb(res.Data);
	    });   
  	}

  	getPaymentTypes(cb){	
  		var url = this.globals.dataUrl + 'businessProductPayment/whiskey/' + this.globals.whiskeyId + '/paymentTypes';

	    this.http.get(
	        url
	    )
	    .subscribe((res:any) => {
			if(typeof cb == "function") cb(res.Data);
	    });   
  	}

  	getCashierProducts(params){	
	  	this.url = this.globals.dataUrl + 'businessProductPayment' + 
	                                      '/page/' + params.page +
	                                      '/size/' + params.size;

	    this.http.get(
	        this.url
	    )
	    .subscribe((res:any) => {
			if(typeof params.cb == "function") params.cb(res.Data);
	    });   
  	}

  	debitConsumer(params){
		return new Promise( (resolve, reject) => {
			this.url = this.globals.dataUrl + 'businessProductPayment/debit/' + params.BusinessId + 
											  '/user/' + params.UserId;

			this.http.post(
				this.url,
				params
			)
			.subscribe(
				data => {
					resolve(data);
				},
				err => {reject(err)}
			);
		});       
	}	

	searchProducts(params){  
	    this.url = this.globals.dataUrl + 'businessProductPayment/search/'+ params.businessId  +                                   
	                                      '/page/' + params.page  +
	                                      '/size/' + params.size  +
	                                      '?term=' + params.term;

		this.http.get(
			this.url
		)
	    .subscribe((res:any) => {
			if(params.cb) params.cb(res.Data);
		});   
  	}    

  	searchOrders(params){  
	    this.url = this.globals.dataUrl + 'businessProductInvoice/search/page/' + params.page  +
	                                      '/size/' + params.size  +
	                                      '?term=' + params.term;

		this.http.get(
			this.url
		)
	    .subscribe((res:any) => {
			if(params.cb) params.cb(res.Data);
		}); 
  	}    

  	searchAllOrders(params){  
	    this.url = this.globals.dataUrl + 'businessProductInvoice/searchAll/page/' + params.page  +
	                                      '/size/' + params.size  +
	                                      '?term=' + params.term;

		this.http.get(
			this.url
		)
	    .subscribe((res:any) => {
			if(params.cb) params.cb(res.Data);
		});   
  	}    

  	getOpenOrders(params){	
		return new Promise( (resolve, reject) => {
		  	this.url = this.globals.dataUrl + 'businessProductPayment/invoices' + 
		                                      '/page/' + params.page +
		                                      '/size/' + params.size;

		    this.http.get(
		        this.url
		    )
		    .subscribe(
				data => {
					resolve(data);
				},
				err => {reject(err)}
		   	);  
		});    	
  	}

  	redeem(params){
	    this.url = this.globals.dataUrl + 'businessProductPayment/redeem/' + params.invoiceId;

	    this.http
	    .put(
	      this.url, 
	      params		      
	    )
	    .subscribe((res:any) => {
	        if(typeof params.cb == "function") params.cb(res);
	    });   
	}

	void(params){
	    this.url = this.globals.dataUrl + 'businessProductPayment/void/' + params.Id;

	    this.http
	    .put(
	      this.url, 
	      params
	    )
	    .subscribe((res:any) => {
	        if(typeof params.cb == "function") params.cb(res);
	    });  
	}

	clear(){
		this.totalAmount = 0;
		this.modifierAmount = 0;
		this.productPrice = 0;
		this.productQty = 0;
	}

	setProductQty(qty){
		this.productQty = qty;

		this.calculatePrice();
	}

	setModifierAmount(modifierAmount){
		this.modifierAmount = modifierAmount;

		this.calculatePrice();
	}

	calculatePrice(){
		if(!this.productQty){
			this.productQty = 0;
		}
		
		this.totalAmount = (this.productPrice + this.modifierAmount) * this.productQty;
	}	
}
