import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Globals } from '../providers/globals';
import { BaseProvider } from '../providers/base-provider';
import { CacheService } from 'src/app/providers/cache.service';
import { Observable } from 'rxjs';

@Injectable()
export class ConsumerBalanceHandlerProvider extends BaseProvider {
	constructor(
		public http: HttpClient,
		public globals: Globals,
		public cacheService: CacheService,
	){
		super();

		this.baseUrl = "consumerMoney";
	}

  	getBalance(businessId){	
		return new Promise( (resolve, reject) => {
		  	this.url = this.globals.dataUrl + 
		  			   this.baseUrl + 
	    		   	   '/balance/' + businessId;

		    this.http.get(
		        this.url			        
		    )
		    .subscribe(
				(data:any) => {
					resolve(data.Data.Balance);
				},
				err => {reject(err)}
		   	);  
		});    	
  	}

	buyCredit(paymentData, businessId){
		return new Promise( (resolve, reject) => {
			this.url = this.globals.dataUrl + 
					   this.baseUrl + 
					   '/buy/' + businessId;
	
			this.http.post(
				this.url,
				paymentData,									
			)
			.subscribe(
				(data:any) => {
					resolve(data);
				},
				err => {reject(err)}
			);
		});       
	}	   	

	getPurchases(businessId){
		return new Promise( (resolve, reject) => {
			this.url = this.globals.dataUrl + 
					   this.baseUrl + 
					   '/purchase/business/' + businessId +
					   '/page/' + this.page  +
					   '/size/' + this.size;		   

			this.http.get(
				this.url								
			)
			.subscribe(
				(res:any) => {
					this.pageData(res.Data)

					resolve(this.list);
				},
				err => {reject(err)}
			);
		});       
	}

	getDebits(businessId){
		return new Promise( (resolve, reject) => {
			this.url = this.globals.dataUrl + 
					   this.baseUrl + 
					   '/debit/business/' + businessId +
					   '/page/' + this.page  +
					   '/size/' + this.size;					

			this.http.get(
				this.url							
			)
			.subscribe(
				(res:any) => {
					this.pageData(res.Data)

					resolve(this.list);
				},
				err => {reject(err)}
			);
		});       
	}	  	

	getPrepayAmounts(businessId){	
		return new Promise( (resolve, reject) => {
		  	this.url = this.globals.dataUrl + 
		  			   'consumerPrepayAmount' + 
					   '/business/' + businessId;

		    this.http.get(
		        this.url			        
		    )
		    .subscribe(
				(data:any) => {
					var amounts = data.Data;
					var defaultSet = false;
					var defaultAmount = {};

					amounts.forEach((amount)=>{
						if(!defaultSet){
							defaultSet = true;
							defaultAmount = amount;
						}	
					});

					resolve({amounts: amounts, defaultAmount: defaultAmount});
				},
				err => {reject(err)}
		   	);  
		});    	
  	}
}