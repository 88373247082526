import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Globals } from '../providers/globals';
import { Storage } from '@ionic/storage';

import { BaseProvider } from '../providers/base-provider';

@Injectable()
export class BusinessMessageProvider extends BaseProvider {
  	punchCardId: any = 0; 

	status: any = {
		SentThisMonth: 0,
		AvailableThisMonth: 0,
	};

	constructor(		
		public http: HttpClient,	
		public globals: Globals,
		public storage: Storage,) {
		super();
	}

	createMessage(params){
	    this.url = this.globals.dataUrl + 'business/message/detail/whiskey/' + this.globals.whiskeyId;

	    this.storage.get('loginData').then((loginData) => {
	      	this.token = loginData.access_token

		    this.http
		    .post(
		      this.url, 
		      params.data
		    )
		    .subscribe(response => {		    		    
		        if(typeof params.cb == "function") params.cb(response);
		    });  
		});           
	}	

	getMessages(params){
		this.url = this.globals.dataUrl + 'business/message/detail' +

										  '/page/' + params.page +
										  '/size/' + params.size;

    	this.storage.get('loginData').then((loginData) => {
		    this.http.get(
		        this.url
		    )
		    .subscribe(
		    	(res:any) => {
		    		if(params.page==1){
		    			this.list = res.Data;
		    		}else{
		    			this.pushItems(res.Data);	
		    		}
		    		
		        	if(typeof params.cb == "function") params.cb(res.Data);
		    	}
		    );   
    	})  
	}

	getMessage(params){
		this.url = this.globals.dataUrl + 'business/message/detail/' + params.id;

    	this.storage.get('loginData').then((loginData) => {
		    this.http.get(
		        this.url
		    )
		    .subscribe(
		    	(res:any) => {
		          if(typeof params.cb == "function") params.cb(res.Data);
		    	}
		    );    
    	});  
	}

	getStatus(){
		this.url = this.globals.dataUrl + 'business/message/detail/status';

    	this.storage.get('loginData').then((loginData) => {
		    this.http.get(
		        this.url
		    )
		    .subscribe(
		    	(res:any) => {
		    		this.status = res.Data;
		    	}
		    );    
    	});  		
	}
}
