import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { Globals } from '../providers/globals';
import { StorageService } from 'src/app/providers/storage.service';

@Injectable()
export class ChatMessageManagerHandler {
 	url: any;
 	token: any;

	constructor(
		public http: HttpClient,
		public globals: Globals,
        public storage: StorageService
	){
	}

	getMessages(params, cb){	
	  	var url = this.globals.dataUrl + 'chatMessage/chatId/'+ params.id + '/page/'+ params.page + '/size/' + params.size;

    	this.storage.get('loginData').then((loginData:any) => {
	      	this.token = loginData.access_token

		    this.http.get(
		        url
		    )
		    .subscribe((res:any) => {
		          if(typeof cb == "function") cb(res.Data);
		    });   
    	})
  	}

  	getMessagesByOrderId(params, cb){	
	  	var url = this.globals.dataUrl + 'chatMessage/order/'+ params.id + '/page/'+ params.page + '/size/' + params.size;

    	this.storage.get('loginData').then((loginData:any) => {
	      	this.token = loginData.access_token

		    this.http.get(
		        url
		    )
		    .subscribe((res:any) => {
		          if(typeof cb == "function") cb(res.Data);
		    });   
    	})
  	}

  	updateInteraction(chatId, cb){	
	  	var url = this.globals.dataUrl + 'consumerChat/updateInteraction/' + chatId;

    	this.storage.get('loginData').then((loginData:any) => {
	      	this.token = loginData.access_token

		    this.http.get(
		        url
		    )
		    .subscribe((res:any) => {
		          if(typeof cb == "function") cb(res.Data);
		    });   
    	})
  	}  	

	create(params, cb){
	    var url = this.globals.dataUrl + 'chatMessage' +
	    			'/whiskey/' + this.globals.whiskeyId;

	    this.storage.get('loginData').then((loginData:any) => {
	      	this.token = loginData.access_token

		    this.http
		    .post(
		      url, 
		      params
		    )
		    .subscribe((res:any) => {
		        if(typeof cb == "function") cb(res.Data);
		    });  
		})           
	}
}
