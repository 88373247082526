import { Injectable } from '@angular/core';
import { BaseProvider } from '../providers/base-provider';

@Injectable()
export class ProductPaymentCheckoutHandler extends BaseProvider {
	businessId: any = 0; 
	invoiceId: any = 0; 

	order: any = {
  	consumer: {},
  	products: [],
  	groups: [{
  		products:[],
  		index: 0,
  		total: 0,
  		groupName: ''
  	}],
  	total: 0,
  	businessId: 0,
    paymentTypeId: null
	};

	activeGroupIndex: 0;

  paymentTypes: any = []

	constructor(
	){		
		super()
	}

	clearOrder(){
		this.order = {
  		consumer: {},
  		products: [],
  		groups: [{
  			products:[],
  			index: 0,
  			total: 0,
  			groupName: ''
  		}],
  		total: 0,
  		businessId: 0,
      paymentTypeId: null
  	};
	}

  calculateTotal() {
    this.order.groups[this.activeGroupIndex].total = 0;

    for(let product of this.order.groups[this.activeGroupIndex].products){
      
      if(product.Count == 0) {
        this.order.products = this.order.products.filter(function(item) {
            return item.IndexId != product.IndexId;
        });

        this.order.groups[this.activeGroupIndex].products = this.order.groups[this.activeGroupIndex].products.filter(function(item) {
            return item.IndexId != product.IndexId;
        });
      }      

      var modifierAmount = (product.ModifierAmount)?product.ModifierAmount:0;

      this      
      .order
      .groups[this.activeGroupIndex]
      .total += (product.Price + modifierAmount) * product.Count;
    }
  }

  removeProduct(product){
    this.order.groups[this.activeGroupIndex].total -= product.Price;

    product.Count = product.Count ? product.Count - 1 : 0;

    if(product.Count == 0) {
      this.order.products = this.order.products.filter((item)=>{
          return item.IndexId != product.IndexId;
      });

      this.order.groups[this.activeGroupIndex].products = this.order.groups[this.activeGroupIndex].products.filter((item)=> {
          return item.IndexId != product.IndexId;
      });
    }    

    this.calculateTotal();
  }

  canStartOrderWithBusiness(businessId){
    let canStartOrder = true;

    console.log("this.order.products.length")
    console.log(this.order.products.length)

    if(this.businessId>0 && this.order.products.length>0){
      canStartOrder = false;
    }

    return canStartOrder;
  }
}
