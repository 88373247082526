import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-consumer-order-registration-detail',
  templateUrl: './consumer-order-registration-detail.page.html',
  styleUrls: ['./consumer-order-registration-detail.page.scss'],
})
export class ConsumerOrderRegistrationDetailPage implements OnInit {
  formData: any;
  formId: any;
  productName: any;
  productDescription: any;

  constructor(
    public navParams: NavParams,
    public modalCtrl: ModalController,
  ) { 
    this.formData = navParams.get('formData'); 
    this.formId = navParams.get('formId');
    this.productName = navParams.get('productName');    
    this.productDescription = navParams.get('productDescription');    
  }

  ngOnInit() {
  }

  cancel(){
    this.modalCtrl.dismiss();   
  }
}
