import { Injectable } from '@angular/core';

import {Observable} from 'rxjs';
	
@Injectable()
export class Globals {	
	//Local
	
	// dataUrl='http://10.211.55.3/cashimiro/';

	// dataUrl='http://localhost/bumerang/'; 
	
 	//Test
 	//dataUrl='https://tumbleweed.azurewebsites.net/';
	//dataUrl='https://cashimiro-test.azurewebsites.net/';

 	//Prod
 	dataUrl='https://cashimiro-prod.azurewebsites.net/';
 	// dataUrl='https://cashimiro-stadium-prod.azurewebsites.net/';
 	// dataUrl='https://cashimiro-test.azurewebsites.net/'; 

 	// pwaUrl='http://www.cashimiro.com/app';

 	reportsUrl='https://cashimiro-admin.azurewebsites.net/'

 	
 	//Storage account path	
 	sa = 'https://cashimirosa.blob.core.windows.net';

 	fileUrl = this.sa + '/uploadedfiles/';

 	iconUrl=  this.sa + '/icons/';

 	pwaUrl='https://www.cashimiro.com/app';

 	oneSignalAppId='826c1fb8-7146-45ed-bf78-c9af154985a0'

 	loginData:any={
 		access_token: ""
 	};
 	currentRole="";
 	messageId="";
 	orderId="";
 	chatId="";
 	
 	mainBusinessId=2273;
 	whiskeyId=31; 	

 	readySourceGlobal: any;

 	registeredAsBusiness= false;

 	productCategories: any = [
 		{
 			Id: 1,
 			Label: "Cannabis",
 			Image: "cannabis.png",
 			IsSelected: false
 		},
 		{
 			Id: 2,
 			Label: "Concentrates",
 			Image: "honey.png",
 			IsSelected: false
 		}, 		
 		{
 			Id: 3,
 			Label: "Deals",
 			Image: "buy.png",
 			IsSelected: false
 		},
 		{
 			Id: 4,
 			Label: "Edibles",
 			Image: "gummy-bear.png",
 			IsSelected: false
 		},
 		{
 			Id: 5,
 			Label: "Event",
 			Image: "tickets.png",
 			IsSelected: false
 		}, 		 
 		{
 			Id: 6,
 			Label: "CBD",
 			Image: "skin-oil.png",
 			IsSelected: false
 		}, 		
 		{
 			Id: 7,
 			Label: "Smoking",
 			Image: "cigarette.png",
 			IsSelected: false
 		}, 	
 		{
 			Id: 8,
 			Label: "Storage",
 			Image: "treasure.png",
 			IsSelected: false
 		}, 	 		 				 		
 		{
 			Id: 9,
 			Label: "Topicals",
 			Image: "sunscreen.png",
 			IsSelected: false
 		}, 	 		
 	]

 	businessCategories: any = [
 		{
 			Id: 1,
 			Label: "Groomers",
 			Image: "brush.png",
 			IsSelected: false
 		},
 		{
 			Id: 2,
 			Label: "Hotels",
 			Image: "dog-house.png",
 			IsSelected: false
 		}, 		
 		{
 			Id: 3,
 			Label: "Trainers",
 			Image: "medal.png",
 			IsSelected: false
 		},
 		{
 			Id: 4,
 			Label: "Walkers",
 			Image: "dog-leads.png",
 			IsSelected: false
 		},
 		{
 			Id: 5,
 			Label: "Vets",
 			Image: "veterinarian.png",
 			IsSelected: false
 		},
 		{
 			Id: 6,
 			Label: "Accessories",
 			Image: "collar.png",
 			IsSelected: false
 		},
 		{
 			Id: 7,
 			Label: "Treats",
 			Image: "snack.png",
 			IsSelected: false
 		}			
 	]

 	weekDays: any = [
 		{
 			Id: 0,
 			Day: "Sunday"
 		},
 		{
 			Id: 1,
 			Day: "Monday"
 		},
 		{
 			Id: 2,
 			Day: "Tuesday"
 		},
 		{
 			Id: 3,
 			Day: "Wednesday"
 		},
 		{
 			Id: 4,
 			Day: "Thursday"
 		},
 		{
 			Id: 5,
 			Day: "Friday"
 		},
 		{
 			Id: 6,
 			Day: "Saturday"
 		},
 	]

 	paymentTypes: any = []
}
	