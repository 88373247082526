import { Injectable } from '@angular/core';
import { Globals } from '../providers/globals';

@Injectable()
export class Utils {
	newguid(){
		var newguid="";

		for (var i=0; i<32; i++){
			newguid += Math.floor(Math.random()*0xF).toString(0xF);
		}

		return newguid;
	}		
		
	today() { 
		var currentdate = new Date(); 
		var datetime =    currentdate.getDate() + "/"
		                + (currentdate.getMonth()+1)  + "/" 
		                + currentdate.getFullYear() + " @ "  
		                + currentdate.getHours() + ":"  
		                + currentdate.getMinutes() + ":" 
		                + currentdate.getSeconds();
		return datetime;		          
	}

	todayDateTime() { 
		var currentdate = new Date(); 
		var datetime =    currentdate.getFullYear() + "-"
		                + (currentdate.getMonth()+1)  + "-" 
		                + currentdate.getDate() + "T"  
		                + currentdate.getHours() + ":"  
		                + currentdate.getMinutes() + ":" 
		                + currentdate.getSeconds();
		return datetime;		          
	}

	days: any = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

	dayIndexToName(date){
		var dayName = this.days[date.getDay()];	

		return dayName;
	}

	timeConvert (time) {
		// Check correct time format and split into components
		time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

		if (time.length > 1) { // If time format correct
		time = time.slice (1);  // Remove full string match value
		time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
		time[0] = +time[0] % 12 || 12; // Adjust hours
		}
		return time.join (''); // return adjusted time or original string
	}	

	getMilliseconds(dateStr){
		return new Date(dateStr).getTime();
	}

	formatTime(dateStr){
		return (new Date(dateStr)).toLocaleTimeString();
	}	

	daysLeftText(date){
		var date1 = new Date(date);
		var today = new Date();

		var timeDiff = Math.abs(today.getTime() - date1.getTime());
		var daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24)); 	

		return 	(daysLeft==1) ? daysLeft + " day left" : daysLeft + " days left";
	}

	pushItemToList(list, item){
		list.push(item);
	}

	removeItemFromList(list, attributeName, item){
		for (var i = list.length - 1; i >= 0; i--) {
			if(list[i][attributeName] == item[attributeName]){
				list.splice(i, 1);
				break;
			}
		}
	}	

	concatenateList(list, attributeName, delimiter){
		var returnVal="";

		for (var i = 0; i < list.length; ++i) {
			returnVal += list[i][attributeName] + 
						 ((i == list.length-1) ? "" : delimiter );
		}

		return returnVal;
	}	

	concatenateListNoAttribute(list, delimiter){
		var returnVal="";

		for (var i = 0; i < list.length; ++i) {
			returnVal += list[i] + 
						 ((i == list.length-1) ? "" : delimiter );
		}

		return returnVal;
	}		

	returnItemFromList(list, attributeName, compareValue){
		let returnVal: any ={};

		for (var i = 0; i < list.length; ++i) {
			if(list[i][attributeName] == compareValue){
				returnVal = list[i];
				break;
			}
		}		

		return returnVal;
	}

	filterList(q, list, searchField) {
		let filter = q.replace(' ', '').toLowerCase();

		return list.filter((item)=>{
			let cleanStr = item[searchField].replace(' ', '').toLowerCase();

			return cleanStr.indexOf(filter)>=0;
		});
	}	

	businessIsAvailable(scheduleJSON) {
		var parsedSchedule = JSON.parse(scheduleJSON);

		var today = new Date();

		var day = this.weekDays.filter(function (el) {
        	return (el.Id === today.getDay());
      	});

		var daySchedule = parsedSchedule.schedule.find(function (el) {
	        return (el.day === day[0].Day);
	    });

	    var time = new Date(today.toLocaleDateString() + ' ' + daySchedule.from);
		var nextTime = new Date(today.toLocaleDateString() + ' ' + daySchedule.to);

		var isOpen = (time.getTime() <= today.getTime() && today.getTime() <= nextTime.getTime())

		return isOpen;
	}

	weekDays: any = [
 		{
 			Id: 0,
 			Day: "Sunday"
 		},
 		{
 			Id: 1,
 			Day: "Monday"
 		},
 		{
 			Id: 2,
 			Day: "Tuesday"
 		},
 		{
 			Id: 3,
 			Day: "Wednesday"
 		},
 		{
 			Id: 4,
 			Day: "Thursday"
 		},
 		{
 			Id: 5,
 			Day: "Friday"
 		},
 		{
 			Id: 6,
 			Day: "Saturday"
 		},
 	]

	generateUUID() {
	  let
	    d = new Date().getTime(),
	    d2 = (performance && performance.now && (performance.now() * 1000)) || 0;
	  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
	    let r = Math.random() * 16;
	    if (d > 0) {
	      r = (d + r) % 16 | 0;
	      d = Math.floor(d / 16);
	    } else {
	      r = (d2 + r) % 16 | 0;
	      d2 = Math.floor(d2 / 16);
	    }
	    return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
	  });
	} 	

	convertMinsToHrsMins(mins){
	  let h = Math.floor(mins / 60);
	  let m = mins % 60;
	  
	  h = h < 10 ? h : h; // (or alternatively) h = String(h).padStart(2, '0')
	  m = m < 10 ? m : m; // (or alternatively) m = String(m).padStart(2, '0')

	  var returnString = "";

	  if(h==0){	  
	  	if(m>1){
	  		returnString = m + " minutes"
	  	}else{
	  		returnString = m + " minute"
	  	}
	  }else if (h!=0 && m==0){
	  	if(h>1){
	  		returnString = h + " hours"	
	  	}else{
	  		returnString = h + " hour"
	  	}	  	
	  }else if (h!=0 && m!=0){
	  	if(h>1){
	  		returnString = h + " hours"	
	  	}else{
	  		returnString = h + " hour"
	  	}	 

	  	if(m>1){
	  		returnString = returnString + " and " + m + " minutes"
	  	}else{
	  		returnString = returnString + " and " + m + " minute"
	  	}
	  }

	  return returnString;
	}	

	formatNumber(n) {
	  // format number 1000000 to 1,234,567
	  return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
	}	

	formatCurrency(input, blur) {
	  // appends $ to value, validates decimal side
	  // and puts cursor back in right position.
	  
	  // get input value
	  var input_val = input.val();
	  
	  // don't validate empty input
	  if (input_val === "") { return; }
	  
	  // original length
	  var original_len = input_val.length;

	  // initial caret position 
	  var caret_pos = input.prop("selectionStart");
	    
	  // check for decimal
	  if (input_val.indexOf(".") >= 0) {

	    // get position of first decimal
	    // this prevents multiple decimals from
	    // being entered
	    var decimal_pos = input_val.indexOf(".");

	    // split number by decimal point
	    var left_side = input_val.substring(0, decimal_pos);
	    var right_side = input_val.substring(decimal_pos);

	    // add commas to left side of number
	    left_side = this.formatNumber(left_side);

	    // validate right side
	    right_side = this.formatNumber(right_side);
	    
	    // On blur make sure 2 numbers after decimal
	    if (blur === "blur") {
	      right_side += "00";
	    }
	    
	    // Limit decimal to only 2 digits
	    right_side = right_side.substring(0, 2);

	    // join number by .
	    input_val = "$" + left_side + "." + right_side;

	  } else {
	    // no decimal entered
	    // add commas to number
	    // remove all non-digits
	    input_val = this.formatNumber(input_val);
	    input_val = "$" + input_val;
	    
	    // final formatting
	    if (blur === "blur") {
	      input_val += ".00";
	    }
	  }
	  
	  // send updated string to input
	  input.val(input_val);

	  // put caret back in the right position
	  var updated_len = input_val.length;
	  caret_pos = updated_len - original_len + caret_pos;

	  // input.find("input").get(0).setSelectionRange(caret_pos, caret_pos);
	}	
}

