import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Globals } from '../providers/globals';
import { BaseProvider } from '../providers/base-provider';
import { CacheService } from 'src/app/providers/cache.service';
import { Observable } from 'rxjs';

@Injectable()
export class ConsumerMoneyDeviceProvider extends BaseProvider {
	constructor(
		public http: HttpClient,
		public globals: Globals,
		public cacheService: CacheService,
	){
		super();

		this.baseUrl = "consumerMoneyDevice";
	}

  	getDevices(params){	
  		return new Promise( (resolve, reject) => {
		  	this.url = this.globals.dataUrl + 'consumerMoneyDevice/business/' + params.businessId;

		    this.http.get(
		        this.url			        
		    )
		    .subscribe(
				(data:any) => {
					this.list = data.Data;
					resolve(data.Data);
				},
				err => {reject(err)}
		   	);  
		});  
  	}		

	addDevice(params){
		return new Promise( (resolve, reject) => {
			this.url = this.globals.dataUrl + 'consumerMoneyDevice/' +
	  				 					  '/business/' + params.businessId +
	  				 					  '/device/' + params.device;

			this.http.post(
				this.url,
				params.payment
			)
			.subscribe(
				(data:any) => {
					resolve(data);
				},
				err => {reject(err)}
			);
		});       
	}	   	

	bind(params){
		return new Promise( (resolve, reject) => {
			this.url = this.globals.dataUrl + 'consumerMoneyDevice/device/' + params.deviceId + 
											  '/bind/' + params.bind;

			this.http.post(
				this.url,
				null,
			)
			.subscribe(
				(data:any) => {
					resolve(data);
				},
				err => {reject(err)}
			);
		});       
	}	
}