import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    constructor(
    ) { }

    async set(key: string, value: any) {
        await Storage.set({
            key: key,
            value: JSON.stringify(value),
        });
    }

    async setObject(key: string, value: any) {
        await Storage.set({
            key: key,
            value: JSON.stringify(value),
        });
    }

    async get(key: string): Promise<{ value: any }> {
        return new Promise( (resolve, reject) => {
            Storage.get({key: key})
            .then((data:any)=>{
                resolve(data.value); 
            });
        });                
    }

    async clear() {
        return await Storage.clear();
    }

    async remove(name){
        Storage.remove({key : name});
    }
}