import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Globals } from '../providers/globals';
import { BaseProvider } from './base-provider';

// import * as firebase from 'firebase';

@Injectable()
export class ChatManagerHandler extends BaseProvider {
 	url: any;
 	token: any;
 	routeName = 'consumerChat';
	notificationCount: number = 0;
	chat: any ;

	constructor(
		public http: HttpClient,
		public globals: Globals
	){
		super();
	}

	getChats(params, cb){	
	  	var url = this.globals.dataUrl + this.routeName  +                                   
                                      '/page/' + params.page  +
                                      '/size/' + params.size  +
                                      '?term=' + params.term;

	    this.http.get(
	        url
	    )
	    .subscribe((res:any) => {
			if(typeof cb == "function") cb(res.Data);
	    });   
  	}

	getChatsPromise(term){	
		return new Promise( (resolve, reject) => {
		  	var url = this.globals.dataUrl + this.routeName  +                                   
	                                      '/page/' + this.page  +
	                                      '/size/' + this.size  +
	                                      '?term=' + term;

		    this.http.get(
		        url
		    )
		    .subscribe((res:any) => {
				this.pageData(res.Data)

				resolve(this.list);		
		    });   
		});   	    
  	}

  	getUsers(params, cb){	
	  	var url = this.globals.dataUrl + this.routeName + '/whiskey/' + this.globals.whiskeyId + 
	  								  '/users/page/' + params.page  +
                                      '/size/' + params.size  +
                                      '?term=' + params.term;

	    this.http.get(
	        url
	    )
	    .subscribe((res:any) => {
	          if(typeof cb == "function") cb(res.Data);
	    });   
  	}

	create(params, cb){
	    var url = this.globals.dataUrl + this.routeName +'/recipientId/' + params.Id;

	    this.http
	    .post(
	      url, 
	      params
	    )
	    .subscribe((res:any) => {
	        if(typeof cb == "function") cb(res.Data);
	    });  
	}

	createForBusiness(params, cb){
	    var url = this.globals.dataUrl + 'consumerChat/business/' + params.businessId;

	    this.http
	    .post(
	      url, 
	      params
	    )
	    .subscribe((res:any) => {
	        if(typeof cb == "function") cb(res.Data);
	    });  
	}

	updateInteraction(id, cb){
	    var url = this.globals.dataUrl + this.routeName +'/updateInteraction/' + id;

	    this.http
	    .get(
	      url,
	    )
	    .subscribe((res:any) => {
	        if(typeof cb == "function") cb(res.Data);
	    });           
	}

	delete(id, cb){	
	  	var url = this.globals.dataUrl + this.routeName + '/' + id;

	    this.http.delete(
	        url
	    )
	    .subscribe((res:any) => {
	          if(typeof cb == "function") cb(res);
	    });   
  	}

  	getMessageCount(cb){	
	  	var url = this.globals.dataUrl + this.routeName + '/messageCount';

	    this.http.get(
			url
	    )
	    .subscribe((res:any) => {
    		this.notificationCount = res.Data ? res.Data : 0;

	        if(typeof cb == "function") cb(res.Data);
	    });   
  	}
}
