import { Component, ElementRef, ViewChild } from '@angular/core';
import { NavController, Platform, ModalController } from '@ionic/angular';
// import { LaunchNavigator, LaunchNavigatorOptions } from '@ionic-native/launch-navigator/ngx';
// import { Geolocation } from '@ionic-native/geolocation/ngx';

import { Globals } from '../../providers/globals';
// import { BusinessMessageDetailImagePage } from '../business-message-detail-image/business-message-detail-image';
import { BusinessMessageProvider } from 'src/app/providers/business-message-handler'

declare var google: any;

@Component({
  selector: 'page-consumer-message-detail',
  templateUrl: './consumer-message-detail.html',
  styleUrls: ['./consumer-message-detail.scss'],
})
export class ConsumerMessageDetailPage {
	message: any
	isLoading: boolean = false;
	saveIsLoading: boolean = false;

	hasCoordinates: boolean = false;
	gettingCoordinates: boolean = false;

	map: any;

	showCloseButton: boolean = false;

	constructor(
		public modalCtrl: ModalController,
		private elRef: ElementRef,
		// private geolocation: Geolocation,
		public globals: Globals,
		public platform: Platform,
		// private launchNavigator: LaunchNavigator,
		public businessMessageProvider: BusinessMessageProvider
		) {
	}

  ionViewDidEnter() {
		if(this.globals.messageId){
			this.showCloseButton = true;
			
			this.load(this.globals.messageId);
			this.globals.messageId = null;
			this.globals.orderId = null;
		}
  }  		

	close(){
 		this.modalCtrl.dismiss();
	}

	loadMap(){
		if(this.message.GeoLat){
		  this.loadGoogleMap({
		    latitude: this.message.GeoLat, 
		    longitude: this.message.GeoLong
		  })
		}    	  		
	}

	loadGoogleMap(coords){
		setTimeout(()=>{
			let el = this.elRef.nativeElement.getElementsByClassName('map');

			this.map = new google.maps.Map(el[0], {
				center: {lat: coords.latitude, lng: coords.longitude},
				zoomControl: false,    
				zoom: 14,
				disableDefaultUI: true,              
			});  

	  		var latLong = {lat: coords.latitude, lng: coords.longitude};      
		  
			var marker = new google.maps.Marker({
				position: latLong,
				map: this.map,
				title: "Your location",          
			});         
		}, 1000);
	}    

	openMap(){
		// this.platform.ready().then((readySource) => {
		//   if(readySource=="dom") return

		//   var coords = this.message.GeoLat + ',' + this.message.GeoLong;

		//   let options: LaunchNavigatorOptions = {
		//     app: this.launchNavigator.APP.GOOGLE_MAPS
		//   };

		//   this.launchNavigator.isAppAvailable(this.launchNavigator.APP.GOOGLE_MAPS)
		//   .then((isAvailable)=>{
		//       var app;

		//       if(isAvailable){
		//           app = this.launchNavigator.APP.GOOGLE_MAPS;
		//       }else{
		//           app = this.launchNavigator.APP.USER_SELECT;
		//       }

		//     this.launchNavigator.navigate(coords, options)
		//     .then(
		//       success => console.log('Launched navigator'),
		//       error => console.log('Error launching navigator', error)
		//     );
		//   })
		//   .catch(function(e){
		//     console.log("Error")
		//     console.log(e)
		//   });
		// });
	}  

	viewImage(imageSrc) {
		// this.navCtrl.push(BusinessMessageDetailImagePage, {
		//   imageSrc: imageSrc
		// })
	}

	load(messageId) {
		var params = {
		  id: messageId,
		  cb: (message)=>{
		    this.message = message[0];
		    this.loadMap();	
		  }
		}

		this.businessMessageProvider.getMessage(params);
	}

}
