import { NgModule, Injector } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, NavParams } from '@ionic/angular';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// import {NgxMaskIonicModule} from 'ngx-mask-ionic'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppInjector } from 'src/app/providers/app-injector.service';
import { AuthInterceptor } from 'src/app/interceptors/http-interceptor';

import { Globals } from 'src/app/providers/globals';
import { AuthProvider } from 'src/app/providers/auth';
import { StorageService } from 'src/app/providers/storage.service';
import { Utils } from 'src/app/helpers/utils';

import { ConsumerBusinessHandlerProvider } from 'src/app/providers/consumer-business-handler';
import { ConsumerBusinessMainHandlerProvider } from 'src/app/providers/consumer-business-main-handler';  
import { ConsumerHandlerProvider } from 'src/app/providers/consumer-handler';
import { ConsumerOrderHandlerProvider } from 'src/app/providers/consumer-order-handler';
import { ConsumerMoneyOrderHandlerProvider } from 'src/app/providers/consumer-money-order-handler';

import { ProductPaymentCheckoutHandler } from 'src/app/providers/product-payment-checkout-handler';

import { BusinessProductPaymentManagerHandler } from 'src/app/providers/business-product-payment-handler';
import { ChatManagerHandler } from 'src/app/providers/chat-handler';
import { ProductModifierHandler } from 'src/app/providers/product-modifier-handler';
import { PaymentMethodService } from 'src/app/providers/payment-method-handler';
import { AddressProvider } from 'src/app/providers/address-handler';
import { PushNotificationHandlerProvider } from 'src/app/providers/push-notification-handler';
import { BusinessMessageProvider } from 'src/app/providers/business-message-handler'
import { ChatMessageManagerHandler } from 'src/app/providers/chat-message-handler';
import { StadiumSeatSelectService } from 'src/app/providers/stadium-seat-select.service';
import { CacheService } from 'src/app/providers/cache.service';
import { ConsumerBalanceHandlerProvider } from 'src/app/providers/consumer-balance-handler';
import { ConsumerMoneyDeviceProvider } from 'src/app/providers/consumer-money-device';
import { BusinessFormsHandler } from 'src/app/providers/business-form-handler';

import { FormioModule } from '@formio/angular';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule, 
    IonicModule.forRoot({mode: "ios", swipeBackEnabled: false}),
    HttpClientModule,
    AppRoutingModule,
    // NgxMaskIonicModule.forRoot(),

    FormsModule,
    ReactiveFormsModule,
    FormioModule
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    FormioModule
  ],  
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
    },

    Globals,
    AuthProvider,           
    Utils,

    BusinessProductPaymentManagerHandler,
    ConsumerBusinessHandlerProvider,
    ConsumerHandlerProvider,
    ConsumerBusinessMainHandlerProvider,
    ProductPaymentCheckoutHandler,
    ConsumerOrderHandlerProvider,
    ProductModifierHandler,
    PaymentMethodService,
    AddressProvider,
    PushNotificationHandlerProvider,
    ConsumerMoneyOrderHandlerProvider,
    BusinessMessageProvider,
    StadiumSeatSelectService,
    ConsumerBalanceHandlerProvider,
    ConsumerMoneyDeviceProvider,
    CacheService,
    
    ChatManagerHandler, 
    ChatMessageManagerHandler,    
    NavParams,
    BusinessFormsHandler
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjector.injector = injector;
  }
}
