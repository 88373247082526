import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Platform, AlertController, MenuController, ModalController, ToastController } from '@ionic/angular';
import { Globals } from '../providers/globals';
import { BaseProvider } from '../providers/base-provider';
import { AuthProvider } from '../providers/auth';

import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';

import { FCM } from "@capacitor-community/fcm";

import { ChatOrderMessageListPage } from 'src/app/pages/chat-order-message-list/chat-order-message-list';
import { ChatMessageListPage } from 'src/app/pages/chat-message-list/chat-message-list';

import { ConsumerMoneyOrderHandlerProvider } from 'src/app/providers/consumer-money-order-handler';

import { ConsumerMessageDetailPage } from 'src/app/pages/consumer-message-detail/consumer-message-detail';
import { ConsumerMoneyOrderDetailPage } from 'src/app/pages/consumer-money-order-detail/consumer-money-order-detail';

@Injectable()
export class PushNotificationHandlerProvider extends BaseProvider {
	constructor(
		public modalCtrl: ModalController, 
		public alertCtrl: AlertController,
		public http: HttpClient,	
		public globals: Globals,
	  	public platform: Platform,
	  	public toastCtrl: ToastController,
	  	public authProvider: AuthProvider,
	  	public consumerMoneyOrderHandlerProvider: ConsumerMoneyOrderHandlerProvider,
	) {
		super();
	}

	async getToken() {
		return new Promise( async (resolve, reject) => {
			console.log('inside getToken');
    	
    	let token;

	    PushNotifications.requestPermissions().then(async (result) => {
	      if (result.receive === 'granted') {
	        // Register with Apple / Google to receive push via APNS/FCM

	        console.log(`before register`);

	        await PushNotifications.register();

	        console.log(`after register`);

					FCM.getToken()
					.then((r) => {
						token = r.token;

						resolve(null);
					})
					.catch((err) => console.log(err));		    			        
	      } else {
	        // Show some error
	      }
	    });    
		});
	}

	handlerNotifications(cb){
	}  

	subscribeToMain(){	
		console.log("subscribing to business: " + this.globals.mainBusinessId);

		try{
			console.log("after try");
			FCM.subscribeTo({ topic: 'business-subscribe-' + this.globals.mainBusinessId });

			console.log('business-subscribe-' + this.globals.mainBusinessId);
			console.log("before catch");
		}catch(e){
			console.log("error");
			console.log(e);
		}

		console.log("subscribed: " + this.globals.mainBusinessId);
	}

	subscribeToBusiness(businessId){	
		FCM.subscribeTo({ topic: 'business-subscribe-' + businessId });
		console.log("subscribeToBusiness: " + businessId);
	}

	unsubscribeToBusiness(businessId){	
		FCM.unsubscribeFrom({ topic: 'business-subscribe-' + businessId });
		console.log("unsubscribeToBusiness: " + businessId);
	}	

	subscribeUserAsBusinessAdmin(businessId){	
		FCM.subscribeTo({ topic: 'business-admin-' + businessId });
		console.log("subscribeToBusiness: " + businessId);
	}	

	subsribeUserByRole(role){
		console.log("subsribeUserByRole")

		if(role=="Consumer"){
			console.log("Consumer")

			FCM.subscribeTo({ topic: 'customer' });
			FCM.subscribeTo({ topic: 'user-' + this.authProvider.user.Id });

			console.log('user-' + this.authProvider.user.Id);
			console.log("after Consumer");

			this.subscribeToMain();
	    } else if(role=="Business"){
	    	console.log("Business")
	    	FCM.subscribeTo({ topic: 'business' });
	    	FCM.subscribeTo({ topic: 'user-' + this.authProvider.user.Id });
	    	FCM.subscribeTo({ topic: 'business-' + this.authProvider.user.BusinessId});
	    	FCM.subscribeTo({ topic: 'business-admin-' + this.authProvider.user.BusinessId });
	    } else if(role=="Driver"){
	    	console.log("Driver")
	    	FCM.subscribeTo({ topic: 'driver' });
	    	FCM.subscribeTo({ topic: 'user-' + this.authProvider.user.Id });
	    } else {
	    	FCM.subscribeTo({ topic: 'business' });
	    	FCM.subscribeTo({ topic: 'business-' + this.authProvider.user.BusinessId });	    
		}
	}

	notificationSetup() {
		return new Promise( async (resolve, reject) => {
		    console.log("inside notificationSetup");

		    this.getToken().then(()=>{
			    // this.onNotificationForeground().subscribe(
			    //   (msg) => {
			    //   	console.log("entered notification foregound");
			    //   	console.log(JSON.stringify(msg));

			    //   	this.managePushNotification(msg, true, false);        	
			    // });

			    // this.onNotificationBackground().subscribe(
			    //   (msg) => {
			    //   	console.log("entered notification background");
			    //   	console.log(JSON.stringify(msg));

		     //  		this.managePushNotification(msg, false, true);
			    // });


			    PushNotifications.addListener(
			      'pushNotificationReceived',
			      (notification: PushNotificationSchema) => {
			        this.managePushNotification(notification, false, true);
			      },
			    );

			    resolve(null);		    	
		    });
		});
	}

	async managePushNotification(msg, prompt, goToDetail){
		console.log("msg.MessageType");
		console.log(msg.MessageType);

		switch (msg.MessageType) {
			case "1": //Message from business
				if(goToDetail){
					this.globals.messageId = msg.MessageId;
		            let modal = await this.modalCtrl.create({
													component:ConsumerMessageDetailPage
								            	});

		            await modal.present();
				}

				if(prompt){
					if (this.platform.is('ios')) {
		          		const toast = await this.toastCtrl.create({
		            		message: msg.BusinessName + " just posted in their timeline.",
		            		duration: 3000,
		            		position: 'top'
				        });

		          		await toast.present();
				    } 	else {
				        let toast = await this.toastCtrl.create({
				            message: msg.BusinessName + " just posted in their timeline.",
				            duration: 3000,
				            position: 'top'
				        });

				        await toast.present();	
				    }
				}

				break;
			case "3": //Order action
				this.globals.orderId = msg.OrderGroupId;
				this.consumerMoneyOrderHandlerProvider.order = {Id: msg.OrderGroupId};

				if(prompt){
					if (this.platform.is('ios')) {
						this.prompt(msg.aps.alert.title, msg.aps.alert.body)
						.then(()=>{
							this.showModalOrderAction(); 							
						});
					} else {
						this.prompt(msg.gcm.title, msg.gcm.body)
						.then(()=>{
							this.showModalOrderAction(); 												
						});
					}
				}

				if(goToDetail){
					this.showModalOrderAction(); 												
				}

				break;
			case "4": //Chat message
				this.globals.orderId = msg.OrderGroupId;
				this.globals.chatId = msg.ChatId;

				var order = {
			   		Id: msg.OrderGroupId,
			   		UserFullName: msg.UserFullName,
			   		OrderNumber: msg.OrderNumber,
			   		BusinessId: msg.BusinessId
			   	}

			   	var chat = {
			   		Id: msg.ChatId,
			   	}

			   	console.log("4")
			   	console.log("goToDetail")
			   	console.log(goToDetail)

				if(goToDetail){
					var modal;

					console.log("goToDetail");

					console.log("order");
					console.log(order);

					if(msg.IsOrder=="True"){
			            modal = await 
	            				this.modalCtrl.create({
									component:ChatOrderMessageListPage,
								    componentProps: {
								    	order: order
								    }											
				            	});						
					} else {
			            modal = await 
	            				this.modalCtrl.create({
									component:ChatMessageListPage,
								    componentProps: {
								    	chat: chat
								    }											
				            	});				
					}

					console.log("after msg.IsOrder=='True'");

		            await modal.present();		

					modal.present();   						

					console.log("modal.present()");
				}

				break;
		}
	}

	async showModalOrderAction(){
	    let modal = await this.modalCtrl.create({
								component:ConsumerMoneyOrderDetailPage
			            	});

	    await modal.present();
	}

	prompt(title, message){
		return new Promise( async (resolve, reject) => {
			let toast = await this.toastCtrl.create({
				message: message,
				duration: 2000,
				position: 'bottom'			
			});

			toast.onDidDismiss().then((data:any) => {
				console.log('Dismissed toast');

				console.log(JSON.stringify(data));

			    if(!data || !data.autoclose){
			    	resolve(null);
			    }				
			});

			await toast.present();
		});    	    
	}

	async testPrompt(){
		var order = {"Id":"9a3fcc75-c825-4ab0-95e0-c0ca1f582c55","UserFullName":"Dan rod","OrderNumber":"8","BusinessId":"2190"};

        var modal = await 
				this.modalCtrl.create({
					component:ChatOrderMessageListPage,
				    componentProps: {
				    	order: order
				    }											
            	});			

        await modal.present();		

		modal.present();   			
	}
}