import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Globals } from '../providers/globals';
import { BaseProvider } from '../providers/base-provider';

@Injectable()
export class BusinessFormsHandler extends BaseProvider {	
	selectedForm: any;
	
	constructor(
		public http: HttpClient,
		public globals: Globals

	) {
		super();

		this.baseUrl = "businessForm";
	}	

	getById(id){
		return new Promise( (resolve, reject) => {
			this.url = this.globals.dataUrl + 
					   this.baseUrl +
					   "/byId?id=" + id;

		    this.http
		    .get(
		    	this.url
		    )
		    .subscribe(
	        (data:any) => {	        	
	        	this.selectedForm = data.Data;

	        	resolve(data.Data)
	       	},
	        (err:any) => {
	        	reject(err)
	       	});
    	});		
	}   		
}