import { FormControl, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, ViewChild } from '@angular/core';
import { NavController, LoadingController, IonContent } from '@ionic/angular';
import { ChatMessageManagerHandler } from '../../providers/chat-message-handler';
import { ChatManagerHandler } from '../../providers/chat-handler';
import { Globals } from '../../providers/globals';
import { BaseManagement } from '../../providers/base-management';
import { ModalController } from '@ionic/angular';

// // import firebase from 'firebase/app';
// import 'firebase/database';
import * as firebase from 'firebase';

@Component({
  selector: 'page-chat-message-list',
  templateUrl: './chat-message-list.html',
  styleUrls: ['./chat-message-list.scss'],
})
export class ChatMessageListPage extends BaseManagement{
  @ViewChild(IonContent, {read: IonContent, static: false}) myContent: IonContent;
  doneLoading = false;

  messages: any;
  chat: any;

  public messageForm: any;
  chatBox: any;
  task: any;
  page: number = 1;
  size: number = 100;

  chatValueEvent: any;  
  firebasedb: any;
  otherParticipant: any = [];
  isFirstEntry: boolean = true;

  showCloseButton: boolean = false;
  showFooter: boolean = true;

  constructor(
    public modalCtrl: ModalController,
    public router: Router,
    public route: ActivatedRoute,
    public navCtrl: NavController,
    public formBuilder: FormBuilder,
    public chatMessageManagerHandler: ChatMessageManagerHandler,
    public chatManagerHandler: ChatManagerHandler,
    public globals: Globals,
    public loadingCtrl: LoadingController) {
    
    super();

    this.messageForm = formBuilder.group({
      message: new FormControl('')
    });
      
    this.chatBox = '';  

    this.chat = this.chatManagerHandler.chat;
  }

  loadFirebase(){
    this.firebasedb = firebase.database()
    .ref('chats/' + this.chat.Id);
    
    this.firebasedb
    .on("value", (resp)=> {
      if(this.justSentMessage){
        this.justSentMessage = false;
        return;
      }

      if(this.isFirstEntry == false){
        this.load(true, null);
      }else{
        this.isFirstEntry = false;
      }
    });      
  }

  ngOnInit(){

    if(!this.chat) {
      this.chat = { "Id": this.route.snapshot.paramMap.get('id') }
    }

    this.load(false, ()=>{
      this.isFirstEntry = true;
      this.loadFirebase();
    });    

    if(this.globals.chatId){
      this.showCloseButton = true;
      
      this.globals.chatId = null;
      this.globals.orderId = null;
    }      
  }

  close(){
    this.showFooter = false;

    this.modalCtrl.dismiss();       
  }

  goBack(){
    this.showFooter = false;
    this.navCtrl.pop();
  }    

  ionViewDidLeave() {
    this.updateInteraction()

    this.firebasedb.off();
  }

  load(isRefreshing, cb){    
    if(this.isSaving()) return;

    if(!isRefreshing){
      this.startWorking("loading");
    }

    var params = {
      page: this.page,
      size: this.size,
      id: this.chat.Id
    }

    this.chatMessageManagerHandler.getMessages(params, (messages:any)=>{
      if(!isRefreshing){
        this.stopWorking()
      }
      
      if(messages){
        this.messages = messages.data;

        messages.participants.forEach((part)=>{
          if(!part.IsMe){
            this.otherParticipant = part.UserId;
          }
        });

        this.scrollToBottom();

        this.chatManagerHandler.notificationCount = 0;

        this.chatMessageManagerHandler.updateInteraction(this.chat.Id, null);

        if(typeof cb == "function")
          cb(null)    
      }  else {
        this.navCtrl.pop()
      }
    })
  }

  justSentMessage: boolean = false;

  send(message) {
    if(message=="") return;

    this.chatBox = '';

    if (message && message !== '') {
      var params = {
        Message: message,
        ChatId: this.chat.Id,
        IsNew: true
      };

      this.chatBox = '';

      this.messages.push({IsNew: true});

      this.scrollToBottom();
      
      this.chatMessageManagerHandler.create(params, (result)=>{
        if(result) {          
            this.justSentMessage = true;

            var lastActivity = {lastActivity: Date.now() };

            this.firebasedb
            .set({lastMessageSent: Date.now() });                        

            firebase.database()
            .ref('chats/user/' + this.otherParticipant)
            .set(lastActivity);

            firebase.database()
            .ref('chats/user/chatlist/' + this.otherParticipant)
            .set(lastActivity);

            this.addCreatedMessage(result);
         }
      });
    }
  }

  addCreatedMessage(msg){
    for (var i = 0; i < this.messages.length; ++i) {
      if(this.messages[i].IsNew){
        this.messages.splice(i, 1);
      }
    }

    this.messages.push(msg);
    this.scrollToBottom();
    this.chatBox = '';
  }

  scrollToBottom() {
    setTimeout(() => {
      this.myContent.scrollToBottom(300);
    }, 100);
  }

  updateInteraction(){
    this.chatManagerHandler.updateInteraction(this.chat.Id, (result)=>{})
  }

  viewProfile(message){

    var message = message
      // this.navCtrl.push(UserVisitorProfilePage, {
      //   UserId: message
      // });        
  }

}

 