import { FormControl, FormBuilder } from '@angular/forms';
import { Component, ViewChild } from '@angular/core';
import { NavController, IonContent, LoadingController, ModalController, NavParams } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { ChatMessageManagerHandler } from 'src/app/providers/chat-message-handler';
import { ChatManagerHandler } from 'src/app/providers/chat-handler';
import { ConsumerMoneyOrderHandlerProvider } from 'src/app/providers/consumer-money-order-handler';
import { Globals } from 'src/app/providers/globals';
import { BaseManagement } from 'src/app/providers/base-management';

import * as firebase from 'firebase';

@Component({
  selector: 'page-chat-order-message-list',
  templateUrl: './chat-order-message-list.html',
  styleUrls: ['./chat-order-message-list.scss'],
})
export class ChatOrderMessageListPage extends BaseManagement {
  @ViewChild(IonContent, {read: IonContent, static: false}) myContent: IonContent;
  doneLoading = false;

  messages: any;
  chat: any;
  order: any;

  @ViewChild(IonContent) content: IonContent;

  public messageForm: any;
  chatBox: any;
  task: any;
  page: number = 1;
  size: number = 100;
  title: any = "";

  firebasedb: any;
  otherParticipants: any = [];

  isFirstEntry: boolean = true;
  showCloseButton: boolean = false;
  showFooter: boolean = true;

  constructor(
    public modalCtrl: ModalController,
    public router: Router,
    public route: ActivatedRoute,
    public navCtrl: NavController,
    public formBuilder: FormBuilder,
    public chatMessageManagerHandler: ChatMessageManagerHandler,
    public chatManagerHandler: ChatManagerHandler,
    public mainService: ConsumerMoneyOrderHandlerProvider,
    public globals: Globals,
    public loadingCtrl: LoadingController,
    public navParams: NavParams
    ) {
    
    super();

    this.messageForm = formBuilder.group({
      message: new FormControl('')
    });        

    this.chatBox = '';

    this.order = navParams.get('order');

    if(this.mainService.order){
      this.id = this.mainService.order.Id;
    }else if(this.order){
      this.id = this.order.Id;
    }else{
      this.id = this.route.snapshot.paramMap.get('id');
    }

    this.title = ""
                 //  this.order.BusinessName 
                 // ? 
                 // this.order.BusinessName //+ " - Order #" + this.order.OrderNumber 
                 // : 
                 // this.order.UserFullName //+ " - Order #" + this.order.OrderNumber;
  }

  loadFirebase(){
    this.firebasedb = firebase.database()
    .ref('chats/' + this.chat);

    this.firebasedb
    .on("value", (resp)=> {
      if(this.isFirstEntry == false){
        this.load(true, null);
      }else{
        this.isFirstEntry = false;
      }
    });      
  }

  ngOnInit(){    
    this.load(false, ()=>{
      this.isFirstEntry = true;
      this.loadFirebase();
    });    

    console.log("this.globals.chatId")
    console.log(this.globals.chatId)

    if(this.globals.chatId){
      this.showCloseButton = true;
      
      this.globals.chatId = null;
      this.globals.orderId = null;
      console.log("this.globals.chatId")
      console.log(this.globals.chatId)      
    }    
  }

  close(){
    this.modalCtrl.dismiss();
  }

  goBack(){
    this.navCtrl.pop();
    this.showFooter = false;
  }  

  ionViewDidLeave() {
    this.updateInteraction()

    this.firebasedb.off();
  }

  load(isRefreshing, cb){    
    if(this.isWorking()) return;

    if(!isRefreshing){
      this.startWorking("loading");
    }

    var params = {
      page: this.page,
      size: this.size,
      id: this.id,
      businessId: this.globals.mainBusinessId
    }

    this.chatMessageManagerHandler.getMessagesByOrderId(params, (result)=>{
      if(!isRefreshing){
        this.stopWorking()
      }

      if(result){
        this.chat = result.chat;
        this.messages = result.messages;

        this.order = result.order;

        this.title = this.order.BusinessName 
                 ? 
                 this.order.BusinessName //+ " - Order #" + this.order.OrderNumber 
                 : 
                 this.order.UserFullName; //+ " - Order #" + this.order.OrderNumber;

        result.participants.forEach((part)=>{
          if(!part.IsMe){
            this.otherParticipants.push(part.UserId);
          }
        });

        this.scrollToBottom();

        if(typeof cb == "function")
          cb(null)    
      }  else {        
        this.navCtrl.pop()
      }
    })
  }

  send(message) {
    if (message && message !== '') {

      var params = {
        Message: message,
        ChatId: this.chat
      }

      this.chatMessageManagerHandler.create(params, (result)=>{
        if(result) {
          var lastActivity = {lastActivity: Date.now() };

          this.otherParticipants.forEach((participanyUserId)=>{
            firebase.database()
            .ref('chats/user/' + participanyUserId)
            .set(lastActivity);
          });

          firebase.database()
          .ref('chats/' + this.chat)
          .set(lastActivity);

          this.messages.push(result);
          this.scrollToBottom();
          this.chatBox = '';
        }
      });
    }
  }

  scrollToBottom() {
    setTimeout(() => {
      this.myContent.scrollToBottom(300);
    }, 100);
  }

  updateInteraction(){
    this.chatManagerHandler.updateInteraction(this.chat, (result)=>{})
  }

  viewProfile(message){

    var message = message
      // this.navCtrl.push(UserVisitorProfilePage, {
      //   UserId: message
      // });        
  }
}

