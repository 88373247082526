import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from 'src/app/guards/auth-guard.service';
import { LoginGuard } from 'src/app/guards/login-guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => 
      import('./pages/consumer-main/consumer-main.module')
      .then( m => m.ConsumerMainPageModule)
  },  
  {
    path: 'login',
    canActivate: [LoginGuard],
    loadChildren: () => 
      import('./pages/login/login.module')
      .then( m => m.LoginPageModule)
  },
  {
    path: 'change-password',
    canActivate: [LoginGuard],
    loadChildren: () => 
      import('./pages/change-password/change-password.module')
      .then( m => m.ChangePasswordPageModule)
  },  
  {
    path: 'register',
    loadChildren: () => 
      import('./pages/register/register.module')
      .then( m => m.RegisterPageModule)
  },    
  {
    path: 'payment-method',
    loadChildren: () => 
    import('./pages/payment-method/payment-method.module')
      .then( m => m.PaymentMethodModule)
  },
  {
    path: 'address-book',
    loadChildren: () => 
    import('./pages/address-book/address-book.module')
      .then( m => m.AddressBookModule)
  },   {
    path: 'consumer-order-splash',
    loadChildren: () => import('./pages/consumer-order-splash/consumer-order-splash.module').then( m => m.ConsumerOrderSplashPageModule)
  },
  {
    path: 'stadium-seat-select-level',
    loadChildren: () => import('./pages/stadium-seat-select-level/stadium-seat-select-level.module').then( m => m.StadiumSeatSelectLevelPageModule)
  },   {
    path: 'consumer-balance',
    loadChildren: () => 
    import('src/app/pages/consumer-balance/consumer-balance.module').then( m => m.ConsumerBalancePageModule)
  },
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
