export class BaseProvider {
	list: any[];
	item: any;
	url: any;
	token: any;
	baseUrl: any;
	
	lastRequestReturnedValues: boolean = false;		
	hasItems: boolean = false;
	currentPageNumber: any = 0;
	totalPages: any = 0;
	page: any = 1;
	size: any = 10;

	constructor() {
		this.list = [];
	}

	pushItem(item){
		this.list.push(item);
	}

	pushItems(items){
		for (var i = 0; i < items.length; i++) {			
			this.list.push(items[i]);
		}		
	}

	updateItem(item){
		let filteredlist: any[] = this.list.filter(d => d.Id === item.Id);

		Object.assign(filteredlist[0], item);
	}

	removeItem(id){
		for (var i = this.list.length - 1; i >= 0; i--) {
			if(this.list[i].Id == id){
				this.list.splice(i, 1);
				break;
			}
		}
	}

	pageData(items){
		this.hasItems = (items.length == 0 && this.page == 1) ? false : true;

		if(this.hasItems){
			this.lastRequestReturnedValues = (items.length==0) ? false : true;

			if(items.length>0){				
				this.currentPageNumber = items[0].PageNumber;
				this.totalPages = items[0].TotalPages;

				if(this.page == 1){
					this.list = items;	
				}else{
					this.pushItems(items);	
				}					
			}			
		}else{
			this.list=[];
			this.totalPages = 0;
		}
	}	
};