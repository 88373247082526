import { Injectable } from '@angular/core';

import {Observable} from 'rxjs';

@Injectable()
export class ConsumerBusinessMainHandlerProvider {
	business: any = {
		Id: 0
	};

	constructor() {

	}
}