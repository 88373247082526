import { Validators, FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';

import { AppInjector } from 'src/app/providers/app-injector.service';

import { ToastController } from '@ionic/angular';

declare var $: any;

export class BaseManagement {
	id: any;
	statusId: any;
	workflowId: any;
	isNew: boolean = false;
	form: any;
	showCloseButton: any;
	
	serverError: any = "";

	saving: {
		isSaving: boolean,
		action: any
	};

	working: {
		isWorking: boolean,
		action: any
	}

	protected toastController: ToastController;
	constructor(
	){		
		this.toastController = AppInjector.injector.get(ToastController); 

		this.saving = {
			isSaving: false,
			action: ''
		};

		this.working = {
			isWorking: false,
			action: ''
		};
	}

	isSaving(){
		return this.saving.isSaving;
	}

	startSaving(action){
		this.serverError = "";
		
		this.saving = {
			isSaving: true,
			action: action
		};
	}

	stopSaving(){
		this.saving = {
			isSaving: false,
			action: ""
		};		
	}

	selectionChange(e){
		setTimeout(()=>{
			this.scrollToSectionHook(e.selectedIndex);

		}, 800);
	}

	scrollToSectionHook(index) { 
		const element = $('.stepperTop' + index).parents("mat-step-header").get(0);

		if(element) { 
			$('.stepperTop' + index).parents(".container.ps")
			.animate({
				scrollTop: $(element).position().top + 70
			});
		} 
	}			

	setRequired(form, controls, isRequired){
		controls.forEach((control)=>{
			if(isRequired){
				form.get(control).setValidators([Validators.required]);
			}else{
				form.get(control).clearValidators();
			}

			form.get(control).updateValueAndValidity();
		});
	}

	async presentToast(msg) {
		const toast = await this.toastController.create({
			message: msg,
			duration: 2000
		});
		toast.present();
	}	
 
 	toFormData<T>( formValue: T ) {
		const formData = new FormData();

		for ( const key of Object.keys(formValue) ) {
			const value = formValue[key];
			formData.append(key, value);
		}

		return formData;
	}	

	isWorking(){
		return this.working.isWorking;
	}	

	isWorkingByAction(action){
		return this.working.isWorking && this.working.action == action;
	}		

	startWorking(action){
		this.working = {
			isWorking: true,
			action: action
		};
	}

	stopWorking(){
		this.working = {
			isWorking: false,
			action: ""
		};		
	}	
};	