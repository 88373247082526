import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Globals } from '../providers/globals';
import { Subject } from 'rxjs';

import { BaseProvider } from './base-provider';
import { Storage } from '@ionic/storage';

@Injectable()
export class AddressProvider extends BaseProvider{
	addresses: any = [];
	selectedAddress: any;
	cities: any = [];
	states: any = [];

	constructor(
		public http: HttpClient,
		public globals: Globals,
        public storage: Storage
	){
		super();
	}

	create(params){
		return new Promise( (resolve, reject) => {
			this.url = this.globals.dataUrl + 'consumerAddress';

			
			this.http.post(
				this.url,
				params
			)
			.subscribe(
          		(res:any) => {
					this.get();

					resolve(res.Data);
				},
				err => {reject(err)}
			);
		});      
	}	 

	update(params){
		return new Promise( (resolve, reject) => {
			this.url = this.globals.dataUrl + 'consumerAddress/' + params.Id;

			
			this.http.put(
				this.url,
				params
			)
			.subscribe(
          		(res:any) => {
					this.get();
					
					resolve(res.Data);
				},
				err => {reject(err)}
			);
		});       
	}	 	

	delete(id){
		return new Promise( (resolve, reject) => {
			this.url = this.globals.dataUrl + 'consumerAddress/' + id;

			this.http.delete(
				this.url
			)
			.subscribe(
          		(res:any) => {
					resolve(res.Data);
				},
				err => {reject(err)}
			);
		});       
	}

	getCityIdByAddressId(addressId){
		var cityId;

		this.addresses.forEach((address)=>{
			if(address.Id == addressId){
				cityId = address.CityId;
			}
		});

		return cityId;
	}

	getAddressJsonById(addressId){
		var returnAddress = {};

		this.addresses.forEach((address)=>{
			if(address.Id == addressId){
				returnAddress = address;
			}
		});

		return returnAddress;		
	}

	get(){
		return new Promise( (resolve, reject) => {
			this.url = this.globals.dataUrl + 'consumerAddress';

			this.http.get(
					this.url
				)
				.subscribe(
          		(data:any) => {
						this.addresses = data.Data;
						this.list = data.Data;

						resolve(data);
					},
					err => {reject(err)}
				);
		});       
	}	

	getById(id){
		return new Promise( (resolve, reject) => {
			this.url = this.globals.dataUrl + 'consumerAddress/' + id;

			this.http.get(
					this.url
				)
				.subscribe(
          		(data:any) => {
						this.item = data.Data;
						this.selectedAddress = data.Data;

						resolve(data);
					},
					err => {reject(err)}
				);
		});       
	}			

	getLookups(){
		return new Promise( (resolve, reject) => {
			if(this.cities.length>0) return;

			this.url = this.globals.dataUrl + 'consumerAddress/lookups';

			
			this.http.get(
				this.url
			)
			.subscribe(
          		(data:any) => {
					this.cities = data.Data.cities;
					this.states = data.Data.states;

					resolve(data);
				},
				err => {reject(err)}
			);
		});  		
	}	
}
