import { Injectable } from '@angular/core';
import { StorageService } from 'src/app/providers/storage.service';
import { Observable } from 'rxjs';

declare var Card: any;
declare var $;
declare var Payment: any;

@Injectable()
export class PaymentMethodService {
	selectedMethod: any = {};

	observers: any = [];
	paymentMethodList: any;
 	paymentMethods: any = [];

 	expMonths: any = [];
 	expYears: any = [];

 	months: any = [
 		{ name: "January", id: "01"}, 
 		{ name: "February", id: "02"}, 
 		{ name: "March", id: "03"}, 
 		{ name: "April", id: "04"}, 
 		{ name: "May", id: "05"}, 
 		{ name: "June", id: "06"}, 
 		{ name: "July", id: "07"}, 
 		{ name: "August", id: "08"}, 
 		{ name: "September", id: "09"}, 
 		{ name: "October", id: "10"}, 
 		{ name: "November", id: "11"}, 
 		{ name: "December", id: "12"}
 	];

	constructor(
		public storage: StorageService
	){
		this.paymentMethodList = Observable.create(observer => {
		  this.observers.push(observer);
		});

		this.getMethods();

		this.generateExpirationArrays();
	}

	generateExpirationArrays(){
		var currentDate = new Date();
		var currentMonth = currentDate.getMonth();
		var currentYear = currentDate.getFullYear();

		this.expMonths = [];
		this.expYears = [];

		this.months.forEach((month)=>{
			// if(month.id >= currentMonth + 1){
				this.expMonths.push(month);
			// }
		});

		var yearToAdd = currentYear;

		this.expYears.push(yearToAdd);

		for (var i = 1; i <= 10; ++i) {
			yearToAdd++;

			this.expYears.push(yearToAdd);
		}
	}

	getMethods(){
		return new Promise( (resolve, reject) => {
			this.paymentMethods = [];

			this.storage.get("paymentMethods").then((data: any)=>{
				if(data){
					this.paymentMethods = JSON.parse(data);				
				}else{
					this.paymentMethods = [];
				}

				resolve(null);
			});				
		});  
	}

	getMethodById(id): any{
		var method = {};

		for (var i = this.paymentMethods.length - 1; i >= 0; i--) {
			if(this.paymentMethods[i].id == id){
				method = this.paymentMethods[i];
				break;
			}
		}

		return method;			
	}

	setCardType(nativeEl, cardNumber){
		var cardType = Payment.fns.cardType(cardNumber);

		console.log("CardType: " + cardType);

		var $jpCard = $(nativeEl).find(".jp-card");
		$jpCard.addClass("jp-card-identified");
		$jpCard.addClass("jp-card-" + cardType)
	}

	getCardType(cardNumber){
		var cardType = Payment.fns.cardType(cardNumber);

		console.log("CardType: " + cardType);		

		return cardType;
	}

	getLast4Digits(method){
		return method.number.substr(method.number.length - 4)
	}	

	getLastBlock(number){
		let arr = number.split(' ');

		return arr[arr.length-1]
	}		

	getCardIndex(number){
		var returnVal = 0;

		for (var i = this.paymentMethods.length - 1; i >= 0; i--) {
			if(this.paymentMethods[i].number == number){
				returnVal = i;
				break;
			}
		}

		return returnVal;	
	}	

	getCard(number){
		var card;

		for (var i = this.paymentMethods.length - 1; i >= 0; i--) {
			if(this.paymentMethods[i].number == number){
				card = this.paymentMethods[i];
				break;
			}
		}

		return card;	
	}		

	getDefaultMethod(){
		var method = {
			id: 0,
			expiry: ""
		};

 		for (var i = this.paymentMethods.length - 1; i >= 0; i--) {
 			if(
 				this.paymentMethods[i].isDefault == true 
 			){
 				method = this.paymentMethods[i];
 				break;
 			}
 		}				

 		return method;
	}

	setAllMethods(){
		this.storage.set('paymentMethods', this.paymentMethods);
	}

	methodShouldBeDefault(id){
		var returnVal = true;

 		for (var i = this.paymentMethods.length - 1; i >= 0; i--) {
 			if(
 				this.paymentMethods[i].id != id
 				&&
 				this.paymentMethods[i].isDefault == true
 			){
 				return false;
 			}
 		}		

 		return returnVal;		
	}

	removeDefaultFromOthers(id){
 		for (var i = this.paymentMethods.length - 1; i >= 0; i--) {
 			if(
 				this.paymentMethods[i].id != id
 			){
 				this.paymentMethods[i].isDefault = false;
 			}
 		}		

 		this.setAllMethods()
	}	

	methodIsUnique(method){
		var returnVal = true;

 		for (var i = this.paymentMethods.length - 1; i >= 0; i--) {
 			if(
 				this.paymentMethods[i].cardType == method.cardType
 				&&
 				this.paymentMethods[i].number == method.number
 				&&
 				this.paymentMethods[i].name == method.name			
 				&&
 				this.paymentMethods[i].id != method.id
 			){
 				return false;
 			}
 		}		

 		return returnVal;
	}

	maskNumber(number){
		let opts = {
			masks: {
				cardNumber: "*"	
			}
		};

		let maskedCardNumber = 
			(new Card(opts)).maskCardNumber(number, null, null);

		return maskedCardNumber;
	}

	defaultMethodHasValidDate(){
		var isValid = false;
		var method = this.getDefaultMethod();

		return this.validateMethodsDate(method.id);
	}

	validateMethodsDate(id){
		var isValid = false;
		var method = this.getMethodById(id);

		try{
			if(Object.keys(method).length==0){
				isValid = true;	
			}else{
				// "12 / 2021"
				var expiry = method.expiry.split("/");
				var month = expiry[0].trim();
				var year = expiry[1].trim();

				if(month.length==2 && year.length==4){
					isValid = true;	
				}							
			}
		}catch(e){}

		return isValid;		
	}

 	addPaymentMethod(method){
 		method.maskedNumber = this.maskNumber(method.number);
 		method.lastBlock = this.getLastBlock(method.number);

	    this.paymentMethods.push(method);

	    this.setAllMethods();
 	}

 	updatePaymentMethod(method){
 		method.maskedNumber = this.maskNumber(method.number);
 		method.lastBlock = this.getLastBlock(method.number);
 		
 		for (var i = this.paymentMethods.length - 1; i >= 0; i--) {
 			if(this.paymentMethods[i].id == method.id){
 				this.paymentMethods[i] = method
 			}
 		}
 		
 		this.setAllMethods();
 	} 	

	removeMethod(id){
 		for (var i = this.paymentMethods.length - 1; i >= 0; i--) {
 			if(
 				this.paymentMethods[i].id == id
 			){
 				this.paymentMethods.splice(i, 1)
 			}
 		}	

 		var method = this.getDefaultMethod();		

 		if(method.id==0){
 			if(this.paymentMethods.length>0){
 				this.paymentMethods[0].isDefault = true;
 			}
 		}

 		this.setAllMethods();
	}

	returnMethodForPayment(id):any{
		var method = this.getMethodById(id);
		var expirationArr = method.expiry.split("/");
		var cardNumber = method.number.replace(/ /g, "");	

		return {
			cardNumber: cardNumber,
			expirationMonth: expirationArr[0].trim(),
			expirationYear: expirationArr[1].trim(),
			cvc: method.cvc
		};
	}
}
